export const cleanMessageBody = (messageBody: string) => {
    // if message body is empty return empty string
    if (!messageBody) {
        return '';
    }

    // find p tag with data-dynamic-needs-list="true" and remove it
    const autoGeneratedRegex = /<p[^>]*data-dynamic-needs-list="true"[^>]*>.*?<\/p>|<digest-skeleton-component[^>]*>.*?<\/digest-skeleton-component>/g;

    return messageBody.replace(autoGeneratedRegex, '');
}