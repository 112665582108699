export const FILE_RENAMED_TOAST_ID = 'file-renamed-toast-id';

export const FILE_UPLOAD_SUCCESS_TOAST_ID = 'file-upload-success-toast-id';

export const FILE_SUBMIT_SUCCESS_TOAST_ID = 'file-submit-success-toast-id';

export const FILE_UPLOAD_UNDO_SUCCESS_TOAST_ID = 'file-upload-undo-success-toast-id';

export const UPLOADING_TOAST_ID = 'global-shoebox-uploading-toast';

export const PACKAGE_GENERATION_TOAST_ID = 'package-generation-toast-id';