import { j as e } from "./jsx-runtime-B6kdoens.js";
import { I as S } from "./Icon-VZwz_Tkb.js";
import { S as i } from "./Stack-9Jnn74Nm.js";
import { T as s } from "./Text-DOFuHqUo.js";
import { c as n } from "./utils-CJ9afRe1.js";
const c = {
  CLEAN: "Clean",
  INFECTED: "Infected",
  NOT_SCANNED: "Scanning",
  NOT_STAGED: "Scanning",
  NOT_CHECKED: "Scanning",
  undefined: "Clean",
  null: "Clean"
}, T = (t) => {
  const a = t.virusScanState === "CLEAN" || !t.virusScanState;
  return /* @__PURE__ */ e.jsxs(
    i,
    {
      row: !0,
      className: n("rounded-lg px-2 py-.5", {
        "bg-yellow-30": t.virusScanState === "NOT_SCANNED" || t.virusScanState === "NOT_STAGED",
        "bg-red-30": t.virusScanState === "INFECTED",
        "bg-green-20": a
      }),
      space: "xs",
      items: "center",
      children: [
        /* @__PURE__ */ e.jsx(
          S,
          {
            name: "VirusShield",
            className: n({
              "text-yellow-70": t.virusScanState === "NOT_SCANNED" || t.virusScanState === "NOT_STAGED",
              "text-destructive": t.virusScanState === "INFECTED",
              "text-green-200": a
            }),
            strokeWidth: 2,
            width: 12,
            height: 12
          }
        ),
        /* @__PURE__ */ e.jsx(
          s,
          {
            size: "xs",
            className: n({
              "text-yellow-70": t.virusScanState === "NOT_SCANNED" || t.virusScanState === "NOT_STAGED",
              "text-destructive": t.virusScanState === "INFECTED",
              "text-green-200": a
            }),
            children: c[t.virusScanState]
          }
        ),
        (t.virusScanState === "NOT_SCANNED" || t.virusScanState === "NOT_STAGED") && /* @__PURE__ */ e.jsx(
          S,
          {
            name: "Refresh",
            className: "text-yellow-70 animate-spin [&_path:last-child]:hidden",
            strokeWidth: 2,
            width: 12,
            height: 12
          }
        )
      ]
    }
  );
};
export {
  T as default
};
